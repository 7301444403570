<template>
  <div class="misc-wrapper">
    <b-link class="brand-logo d-flex align-items-center">
      <aiot-logo style="width: 60px;height: 60px"/>
      <span class="x-text-bold text-primary ml-1 font-large-1">
        极熊座
      </span>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 d-flex flex-column text-center">
        <h2 class="mb-1">
          很抱歉，您无权访问该页面
        </h2>
        <p class="mb-2 text-secondary">
          请检查您是否有该页面访问权限，或联系管理员，您可以
        </p>
        <b-button
          variant="primary"
          class="mb-1 m-auto"
          :to="loginRoute()"
        >返回首页</b-button>
        <b-img
          fluid
          :src="imgUrl"
          alt="Not authorized page"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BLink, BImg, BButton } from 'bootstrap-vue'
import AiotLogo from '@core/layouts/components/Logo.vue'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  components: {
    BLink, BImg, BButton, AiotLogo,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/not-authorized.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (this.$store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/not-authorized-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  methods: {
    loginRoute() {
      const user = JSON.parse(localStorage.getItem('userData'))
      return getHomeRouteForLoggedInUser(user ? user.role : null)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
